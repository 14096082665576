<template>
    <vue3-seamless-scroll :list="ecoArticleList" direction="left" hover class="scroll">
       <ul class="item">
            <li v-for="(item, index) in ecoArticleList" :key="index" @click="getEcoArticleInfoFun(item.id)">{{item.title}}({{parseTime(item.date,'{y}/{m}/{d}')}})</li>
        </ul>
    </vue3-seamless-scroll>
    <el-dialog custom-class="dialog-c" v-model="isVisible" destroy-on-close width='60%' title="经济舆情">
      <div  class="info-header">
        <h3>{{ecoArticleInfo?.title}}</h3>
        <div>
          <span>日期：{{ecoArticleInfo?.date}}</span>
        </div>
        <div style="text-align:right;">  
          <span>
              来源：{{ecoArticleInfo?.regionName}}统计局
          </span>
          <el-link type="primary" class="link-a" :href="ecoArticleInfo?.url" target="_blank">链接</el-link>
        </div>
      </div>
      <el-scrollbar style="height:460px;">
        <!-- <div v-html='ecoArticleInfo?.content'></div> -->
        <div>
          <v-md-preview :text="ecoArticleInfo?.content"></v-md-preview>
        </div>
      </el-scrollbar>
    </el-dialog>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import axios from 'axios';
import {getEcoArticleList,getEcoArticleInfo} from "@/http/policyApi"
import {addUserLog} from '@/http/basicsApi.js'
import {parseTime} from '@/utils'
import { useRouter } from "vue-router";
export default defineComponent({
  name: "App",
  components: {
    Vue3SeamlessScroll
  },
  props: {
    regionCode: String,//地区编号
    },
  setup(props) {
    let isVisible=ref(false);//经济舆情列表是否显示
    let ecoArticleList=ref(null);//经济舆情列表
    let router=useRouter();
     //获取经济舆情列表
    const getEcoArticleListFun=async()=>{
      const params={
        regionCode:props.regionCode,
        pageIndex:1,
        pageSize:10,
      }
      console.log(params);
      const result=await getEcoArticleList(params);
        //console.log(result);
        if(result.code==20000){
          
          ecoArticleList.value=result.data.dataList;
        }
        else{
          ecoArticleList.value=null;
        }
      }
    //单击经济舆情显示详细信息
    
    let ecoArticleInfo=ref(null);
    const getEcoArticleInfoFun=async(id)=>{
      //console.log(id);
      isVisible.value=true;
      const result=await getEcoArticleInfo(id);
      console.log(result);
      if(result.code==20000){
        ecoArticleInfo.value=result.data;
        if(ecoArticleInfo.value.path){
          axios.get(ecoArticleInfo.value.path).then((response) => {
            console.log(response);
            ecoArticleInfo.value.content = response.data;
          });
        }
      }
      else{
        ecoArticleInfo.value=null;
      }
      //记录用户操作日志
      addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'查看舆情信息',JSON.stringify({ecoArticle:id}));
    }
    //监听 regionCode变化时，舆情列表重新获取
    watch(()=>props.regionCode,(newVal,oldVal)=>{// eslint-disable-line no-unused-vars
      //加载
      //console.log('经济舆情');
      if(newVal){
        getEcoArticleListFun();
      }

    },
      {
        // 首次渲染组件就触发一次
        immediate: true,
        // 开启深度监听,对象里面的数据如果发生变化也会被侦听到
        // 如果监听的数据是一个比较长的表达式，那么需要用一个函数的方式
        // 但是写成函数形式之后，里层的数据变化不到，所以需要添加deep选项
        deep: true
      })
    return { 
        parseTime,
        ecoArticleList,
        isVisible,
        ecoArticleInfo,
        getEcoArticleInfoFun
        };
  },
});
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.scroll {
 
  width: calc(100% - 160px);
  margin-left: 150px;
  margin-right: 30px;
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.scroll .item {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
    li{
        display:inline-block;
        margin-left: 20px;
        cursor: pointer;
        list-style: none;
        font-size: 16px;
        width: 400px;
        overflow: hidden;//溢出隐藏
        white-space: nowrap;//禁止换行
        text-overflow: ellipsis;
        color:$specialtext-color;
    }
}
.link-a
{
    font-size: 14px;
    vertical-align: inherit;
    color:$themeColor!important;
}
.link-a:hover
{
    color:  $themeColor !important;
}
.info-header{
  text-align:center;
  border-bottom:1px solid $bordercolor;
  position:relative;
  padding-bottom: 10px;
}
</style>
