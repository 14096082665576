//调用政策库的接口
import $http from './index'
//获取经济舆情列表
export const getEconoStatisticalList=(data)=>{
  return $http.post(`/econo/queryEconomicsPublicOpinionList`,data);
}
//获取具体经济舆情的详细信息
export const getEconoStatisticalInfo=(data)=>{
  return $http.post(`/econo/queryEconomicsPublicOpinionMessage`,data);
}
//获取经济舆情列表
export const getEcoArticleList=(data)=>{
  return $http.post(`/api/EcoArticle/GetEcoArticleList`,data);
  }
  //获取具体经济舆情的详细信息
  export const getEcoArticleInfo=(ecoArticleId)=>{
  return $http.get(`/api/EcoArticle/GetEcoArticleInfo?ecoArticleId=`+ecoArticleId);
  }
